import React from 'react';
import ReactDOM from 'react-dom/client';
import {
  createBrowserRouter,
  RouterProvider,
} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';


import './index.css';
import ErrorPage from './Pages/Error/ErrorPage';
import Home from './Pages/Home/Home';
import Minesweeper from './Pages/Minesweeper/Minesweeper';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import VerjaardagChris from './Pages/VerjaardagChris/VerjaardagChris';
// import reportWebVitals from './reportWebVitals';

const router = createBrowserRouter([
  {
    path: "/minesweeper",
    element: <Minesweeper />,
    errorElement: <ErrorPage />
  },
  {
    path: "/gefeliciteerd",
    element: <VerjaardagChris />,
    errorElement: <ErrorPage />
  },
  {
    path: "/",
    element: <Home />,
    errorElement: <ErrorPage />
  },
]);

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <ThemeProvider theme={darkTheme}>
      <CssBaseline />
      <RouterProvider router={router} />
    </ThemeProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
