import { Button, TextField } from '@mui/material';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './Minesweeper.css';
import MinesweeperGame from './MinesweeperGame';

const Minesweeper = () => {
    const [height, setHeight] = useState<number>(10);
    const [width, setWidth] = useState<number>(10);
    const navigate = useNavigate();

    const updateNumber = (input: string, cb: (x: number) => void) => {
        const parsed = parseInt(input);
        if (isNaN(parsed)) return;
        if (parsed < 4 || parsed > 25) return;
        cb(parsed);
    }

    return (
        <div className='page-container'>
            <h1>Minesweeper</h1>
            {/* Playing area */}
            <div className='game-area'>
                <MinesweeperGame height={height} width={width} />
            </div>
            {/* Buttons */}
            <div className='page-buttons'>
                <div>
                    <TextField id="standard-basic" label="Height" variant="outlined" onChange={e => updateNumber(e.currentTarget.value, setHeight)} value={height} />
                    <TextField id="standard-basic" label="Width" variant="outlined" onChange={e => updateNumber(e.currentTarget.value, setWidth)} value={width} />
                </div>
                <Button variant='contained' onClick={() => navigate('/')}>Home</Button>
            </div>
        </div>
    );
}

export default Minesweeper;