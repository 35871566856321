import { useState } from "react";

enum GridTileState {
    OPEN='open',
    HIDDEN='hidden',
    FLAGGED='flagged',
}

class GridTile {
    public state: GridTileState;
    public bomb: boolean;
    private x: number;
    private y: number;
    public adjacentBombs: number;

    constructor(x: number, y: number, state?: GridTileState, bomb?: boolean, adjacentBombs?: number) {
        this.state = state || GridTileState.HIDDEN;
        this.bomb = bomb || false;
        this.x = x;
        this.y = y;
        this.adjacentBombs = adjacentBombs || 0;
    }

    public copy(): GridTile {
        return new GridTile(this.x, this.y, this.state, this.bomb, this.adjacentBombs)
    }

    public toggleFlag() {
        switch (this.state) {
            case GridTileState.OPEN:
                return;
            case GridTileState.HIDDEN:
                this.state = GridTileState.FLAGGED;
                return;
            case GridTileState.FLAGGED:
                this.state = GridTileState.HIDDEN;
        }
    }
}

export enum MinesweeperState {
    NOT_STARTED,
    STARTED,
    LOST,
    WON
}

class MinesweeperGame {
    public grid: GridTile[][];
    public state: MinesweeperState;
    private height: number;
    private width: number;

    // x horizontal
    // y vertical
    // origin is top left
    // 0-indexed
    // grid[x][y]
    constructor(height: number, width: number, state?: MinesweeperState, grid?: GridTile[][]) {
        this.height = height;
        this.width = width;
        if (grid) {
            this.grid = grid;
        } else {
            this.grid = [];
            for (let x = 0; x < width; x++) {
                const column = [];
                for (let y = 0; y < height; y++) {
                    column.push(new GridTile(x, y));
                }
                this.grid.push(column);
            }
        }
        this.state = state || MinesweeperState.NOT_STARTED;
    }

    public copy(): MinesweeperGame {
        return new MinesweeperGame(this.height, this.width, this.state, this.grid);
    }

    public rightClick(x: number, y: number): MinesweeperGame {
        if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
            return this;
        }

        switch (this.state) {
            case MinesweeperState.STARTED:
                const newState = this.copy();
                newState.getSquare(x, y)?.toggleFlag();
                return newState;
                case MinesweeperState.NOT_STARTED:
            case MinesweeperState.LOST:
            case MinesweeperState.WON:
                return this;
        }
    }

    public click(x: number, y: number): MinesweeperGame {
        if (x < 0 || x >= this.width || y < 0 || y >= this.height) {
            return this;
        }
        
        switch (this.state) {
            case MinesweeperState.NOT_STARTED:
                const newState = this.copy();
                newState.firstClick(x, y);
                return newState;
            case MinesweeperState.STARTED:
                const newState2 = this.copy();
                newState2.reveal(x, y);
                return newState2;
            case MinesweeperState.LOST:
            case MinesweeperState.WON:
                return this;
        }
    }

    public firstClick(x: number, y: number) {
        this.state = MinesweeperState.STARTED;
        // Add bombs to the game randomly until desired number is reached
        let addedBombs = 0;
        let desiredBombs = Math.round(this.width * this.height / 5)

        // Do not add bombs to the initial square and squares around it
        while (addedBombs < desiredBombs) {
            let randomX = Math.floor(Math.random() * this.width);
            let randomY = Math.floor(Math.random() * this.height);
            if (this.isAdjacent(x, y, randomX, randomY) || this.grid[randomX][randomY].bomb) {
                continue;
            }

            this.grid[randomX][randomY].bomb = true;
            addedBombs++;
        }

        // Calculate all adjacent bomb numbers
        for (let i = 0; i < this.width; i++) {
            for (let j = 0; j < this.height; j++) {
                let amount = 0;
                const adjacentTiles = this.getAdjacentSquares(i, j).map(coord => this.getSquare(coord[0], coord[1]));
                for (const tile of adjacentTiles) {
                    if (tile && tile.bomb) {
                        amount++;
                    }
                }
                this.getSquare(i, j)!!.adjacentBombs = amount;
            }
        }

        // Reveal the clicked tile
        this.reveal(x, y);
    }

    public reveal(x: number, y: number) {
        const square = this.getSquare(x, y);
        if (!square) { return; }
        if (square.state === GridTileState.OPEN) { return; }
        if (square.state === GridTileState.FLAGGED) { return; }

        square.state = GridTileState.OPEN;

        if (square.bomb) {
            this.state = MinesweeperState.LOST;
            return;
        }

        if (square.adjacentBombs === 0) {
            const adjacents = this.getAdjacentSquares(x, y);
            for (const adjacent of adjacents) {
                if (adjacent) {
                    this.reveal(adjacent[0], adjacent[1]);
                }
            }
        }

        this.checkVictory();
    }

    public checkVictory() {
        const flatTiles = this.grid.reduce((prev, curr) => prev.concat(curr), []);
        // Victory if no bomb tiles remain hidden
        const victory = flatTiles.filter(t => !t.bomb && t.state === GridTileState.HIDDEN).length === 0;
        if (victory) {
            this.state = MinesweeperState.WON;
        }
    }

    private isAdjacent(x1: number, y1: number, x2: number, y2: number): boolean {
        return Math.abs(x1 - x2) <= 1 && Math.abs(y1 - y2) <= 1
    }

    private getAdjacentSquares(x: number, y: number): number[][] {
        return [
            [x-1, y-1],
            [x-1, y],
            [x-1, y+1],
            [x, y-1],
            [x, y+1],
            [x+1, y-1],
            [x+1, y],
            [x+1, y+1],
        ]
    }

    private getSquare(x: number, y: number): GridTile | undefined {
        try {
            return this.grid[x][y];
        } catch {
            return undefined;
        }
    }
}

const useMinesweeperGame = (height: number, width: number) => {
    const [gameState, setGameState] = useState<MinesweeperGame>(new MinesweeperGame(height, width));
    const click = (x: number, y: number) => setGameState(gameState.click(x, y));
    const rightClick = (x: number, y: number) => setGameState(gameState.rightClick(x, y));
    const restart = () => setGameState(new MinesweeperGame(height, width));
    const updateSize = (newHeight: number, newWidth: number) => { 
        setGameState(new MinesweeperGame(newHeight, newWidth));
    };

    
    return {
        gameState,
        click,
        rightClick,
        restart,
        updateSize,
    };
};

export default useMinesweeperGame;