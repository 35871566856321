import { useState } from 'react';
import styles from './VerjaardagChris.module.css';
import chest from './chest.png';
import bericht from './chrisBericht.jpg'

const VerjaardagChris = () => {
  const [clicked, setClicked] = useState(false);
  return (
    <div className={styles.container}>
      <h1>Gefeliciteerd Chris!</h1>
      {!clicked &&
        <div className={styles.cadeauContainer}>
          <h2>Open je cadeau!</h2>
          <img className={styles.present} onClick={() => setClicked(true)} src={chest} alt="Box" />
        </div>
      }
      {clicked &&
        <>
          <iframe className={styles.video} src="https://www.youtube.com/embed/JRHARtLZLk8?autoplay=1&controls=0" title="YouTube video player" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen></iframe>
          <div className={styles.copyPasta}>
            <h2>
              Oopsie Woopsie 😳😳 ons plawn 🤔 is stukkie wukkie 🔥❌ 
              Chrissie wissie 💯💯 heefwt Jown Wiwk 🔫🧔 al gezien owO 👀😶
            </h2>
            <img className={styles.present} src={bericht} alt="Bericht" />
            <h3>
              Nu ⏲ moeten 👮‍♂️ we ons 🛠 plawn 📉 aanpassewuu 😭
              Dusw 😤 bij dezew ⬇ een gepersonaliseeuwd 🤵 gedichwt:
            </h3>
            <p>
              Well you see it's like this. A herd of buffalo can only move as fast as the slowest buffalo.
              And when the herd is hunted, it’s the slowest and weakest ones at the back that are killed first.
              This natural selection is good for the herd as a whole, because the general speed and health of the whole group keeps improving by the regular killing of the weakest members.
            </p>
            <p>
              In much the same way, the human brain can only operate as fast as the slowest brain cells.
              Now, as we know, excessive intake of alcohol kills brain cells.
              But naturally, it attacks the slowest and weakest brain cells first.
              In this way, regular consumption of beer eliminates the weaker brain cells, making the brain a faster and more efficient machine.
            </p>
            <p>
              And that is why you always feel smarter after a few beers.
            </p>
            <p>- Morgan Freeman</p>
          </div>
        </>
      }
    </div>
  );
}

export default VerjaardagChris;
