import { Button } from "@mui/material";
import { useEffect } from "react";
import useMinesweeperGame, { MinesweeperState } from "./useMinesweeperGame";

interface IMinesweeperGameProps {
    width: number;
    height: number;
}

const MinesweeperGame = (props: IMinesweeperGameProps) => {
    const { gameState, click, rightClick, restart, updateSize } = useMinesweeperGame(props.height, props.width);

    useEffect(() => {
        updateSize(props.height, props.width);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.height, props.width]);

    const gameOver = gameState.state === MinesweeperState.LOST || gameState.state === MinesweeperState.WON;

    return ( 
        <>
            <div className='grid'>
                {gameState.grid.map((column, x) => 
                    <div className='grid-column' key={`column-${props.height}-${props.width}-${x}`}>
                        {column.map((tile, y) => 
                            <div className={`grid-tile ${tile.state}`} key={`tile-${props.height}-${props.width}-${x}-${y}`} onClick={() => {click(x, y)}} onContextMenu={(e) => {rightClick(x, y); e.preventDefault();}}>
                                <div>{tile.bomb ? 'B' : (tile.adjacentBombs > 0 ? tile.adjacentBombs : '')}</div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            <div className={`grid-overlay ${gameOver ? 'show' : ''}`}>
                {gameState.state === MinesweeperState.LOST && 
                <div>
                    <h2>You died...</h2>
                    <Button variant='contained' onClick={() => restart()}>Restart</Button>
                </div>}
                {gameState.state === MinesweeperState.WON && 
                <div>
                    <h2>You won!</h2>
                    <Button variant='contained' onClick={() => restart()}>Restart</Button>
                </div>}
            </div>
        </>
     );
}

export default MinesweeperGame;