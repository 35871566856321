import logo from './logo.svg';
import './Home.css';
import { Button } from '@mui/material';
import { useNavigate } from 'react-router-dom';

interface IRoute {
  name: string,
  route: string,
};

const Home = () => {
  const navigate = useNavigate();
  
  const routes: IRoute[] = [
    {
      name: 'Minesweeper',
      route: '/minesweeper',
    }
  ];

  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          This is not the greatest site in the world.
        </p>
        <p>
          <small>This is just a tribute.</small>
        </p>
        <div className='routes-container'>
          {routes.map(r => <Button variant="contained" onClick={() => navigate(r.route)}>{r.name}</Button>)}
        </div>
      </header>
    </div>
  );
}

export default Home;
